import * as React from 'react';
import '../assets/scss/main.scss';
import { Layout, SEO } from '../components';

import { Header } from '../components';
import Banner from '../components/restaurant/banner';
import Wallpaper from '../components/restaurant/wallpaper';
import Certificaciton from '../components/certificaciton';
import Footer from '../components/footer';
import Contact from '../components/contact';
import Map from '../components/map';
import IconWrapper from '../components/icon-wrapper';

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Header link={true} />
      <IconWrapper />
      <Banner />
      <Wallpaper />
      <Contact />
      <Map />
      <Certificaciton />
      <Footer color="white" />
    </Layout>
  );
};

export default IndexPage;
