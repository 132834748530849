module.exports = [
  {
    title: 'restaurante',
    id: 'restaurant',
    p: 'Contamos con una gastronomia unica de la region',
    style: '',
    items: [
      {
        name: 'Horario: 7:00 am a 9:00 pm',
        icon: 'cabin.jpg',
        float: 'left',
        description:
          'Ofrecemos servicio de alimentación (desayunos, almuerzos y cenas) con productos propios de la región y preparados por personas de la comunidad en fogones de leña. Predomina el pescado, la carne de gallina y productos agrícolas como la yuca, el plátano y el maíz, todos producidos en Playa Güío por la comunidad.',
      },
    ],
  },
];
