import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import dataConfig from '../../config/restaurant';

const useHousingImg = () => {
  const data = useStaticQuery(graphql`
    {
      restaurantPages: allFile(filter: { relativeDirectory: { eq: "restaurant" } }) {
        nodes {
          childImageSharp {
            sizes {
              srcWebp
            }
          }
        }
      }
    }
  `);
  return data;
};

const Restaurant = () => {
  const { id, items } = dataConfig[0];
  const data = useHousingImg();
  return (
    <section id={id} className="wrapper white restaurant">
      {items.map((row, i) => (
        <article key={i} className="content">
          <div className="container_image">
            <swiper-container
              navigation="true"
              pagination="true"
              keyboard="true"
              mousewheel="true"
              css-mode="true"
            >
              {data.restaurantPages.nodes.map(
                ({
                  childImageSharp: {
                    sizes: { srcWebp: publicURL },
                  },
                }) => (
                  <swiper-slide>
                    <img src={publicURL || null} alt={`icon-${row.name}`} />
                  </swiper-slide>
                ),
              )}
            </swiper-container>
          </div>
          <div className="container_text content">
            <div className=" wow animate__animated animate__bounce animate__fadeInRight">
              <header>
                <h2 className={`aling-${row.float}`}>{row.name}</h2>
              </header>
              <p>{row.description}</p>
            </div>
          </div>
        </article>
      ))}
    </section>
  );
};

export default Restaurant;
